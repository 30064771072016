import React, { Component } from "react"
import css from "./stylesheets/Modal.module.scss"

class Modal extends Component {
  constructor(props) {
    super(props)
    this.doReqestCloseModal = this.doReqestCloseModal.bind(this)
    this.doClickOutside = this.doClickOutside.bind(this)
    this.handleKeyDown = this.handleKeyDown.bind(this)
  }

  doReqestCloseModal(e) {
    if (this.props.onRequestClose) {
      this.props.onRequestClose()
    }

    e && e.preventDefault()
  }

  doClickOutside(e) {
    if (e.target.classList.contains(`modalBase`)) {
      this.doReqestCloseModal()
    }
    e.preventDefault()
  }

  handleKeyDown(e) {
    if (e.keyCode === 27) {
      this.doReqestCloseModal()
    }
  }

  render() {
    if (typeof window !== "undefined") {
      if (this.props.isOpen) {
        window.addEventListener("keydown", this.handleKeyDown)
      } else {
        window.removeEventListener("keydown", this.handleKeyDown)
      }
    }

    let styles = css.off
    if (this.props.isOpen) {
      styles = css.on
    }

    return (
      <div
        id={this.props.id}
        className={css.base + ` ` + styles}
        onMouseDown={this.doClickOutside}
        role="presentation"
      >
        <div className={css.inner}>
          <div className={css.closeBox}>
            <a
              className={css.close}
              href={`#` + this.props.id}
              onClick={this.doReqestCloseModal}
            >
              Close
            </a>
          </div>
          {this.props.children}
        </div>
      </div>
    )
  }
}

export default Modal
