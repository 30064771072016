import React, { Component } from "react"
import css from "./stylesheets/QuestionAndAnswer2column.module.scss"

class QuestionAndAnswer2colmun extends Component {
  render() {
    return (
      <div className={css.base}>
        <div className={css.left}>{this.props.children[0]}</div>
        <div className={css.right}>{this.props.children[1]}</div>
      </div>
    )
  }
}

export default QuestionAndAnswer2colmun
