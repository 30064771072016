import React, { Component } from "react"
import css from "./stylesheets/ModalLink.module.scss"
import Modal from "./Modal"
import ReactDOM from "react-dom"

class ModalLink extends Component {
  constructor(props) {
    super(props)
    this.doOpenModal = this.doOpenModal.bind(this)
    this.doCloseModal = this.doCloseModal.bind(this)
  }

  doOpenModal(e) {
    this.modalCanvas = document.createElement("div")
    document.querySelector("body").appendChild(this.modalCanvas)

    ReactDOM.render(
      <Modal
        id={this.props.id}
        isOpen={true}
        onRequestClose={this.doCloseModal}
      >
        {this.props.children[1]}
      </Modal>,
      this.modalCanvas
    )
    e.preventDefault()
  }

  doCloseModal() {
    ReactDOM.unmountComponentAtNode(this.modalCanvas)
    this.modalCanvas.remove()
  }

  render() {
    let styles = this.props.style ? this.props.style : {}
    let className = this.props.className ? this.props.className : {}

    return (
      <a
        className={css.link + ` ` + className}
        style={styles}
        href={`#` + this.props.id}
        onClick={this.doOpenModal}
      >
        {this.props.children[0]}
      </a>
    )
  }
}

export default ModalLink
