import React, { Component } from "react"
import css from "./stylesheets/ColumnGrid.module.scss"

/**
 * 単純に均等割したグリッドレイアウトで配置します。
 *
 * children の数に応じて自動的にカラムレイアウト化します。
 * 以下の場合、 4 カラムレイアウトになります。
 * <ColumnGrid>
 *   <item />
 *   <item />
 *   <item />
 *   <item />
 * </ColumnGrid>
 *
 * 明示的にカラム数を指定したい場合 column アトリビュートにカラム数 (1 ~ 10) を渡してください。
 * 以下の場合、 2 カラム 2 段のレイアウトになります。
 *
 *
 * <ColumnGrid  column={4} columnSp={2}>
 *   <item />
 *   <item />
 *   <item />
 *   <item />
 * </ColumnGrid>
 *
 */


class ColumnGrid extends Component {
  render() {

    let list = []

    let gridClass = ``
    if(this.props.column){
      gridClass = css[`grid` + this.props.column]
    }

    let gridSpClass = ``
    if(this.props.columnSp){
      gridSpClass = css[`gridSp` + this.props.columnSp]
    }

    for (let i = 0; i < this.props.children.length; i += 1) {
      list.push(
        <div
          key={`list-item-` + i}
          className={gridClass +` ` + gridSpClass}
        >
          {this.props.children[i]}
        </div>
      )
    }

    return <div className={css.base}>{list}</div>
  }
}

export default ColumnGrid
