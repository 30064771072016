import React from "react"
import Info2column from "../../../components/Info2column"
import QuestionAndAnswer2colmun from "../../../components/QuestionAndAnswer2colmun"
import CommentText from "../../../components/CommentText"
import ColumnGrid from "../../../components/ColumnGrid"
import CommonButton from "../../../components/CommonButton"
import ModalLink from "../../../components/ModalLink"
import LayoutInterview from "../../../components/LayoutInterview"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import Seo from "../../../components/Seo"

import IconInfo from "../../../images/icon_info.svg"
import IconPhoto from "../../../images/icon_photo.svg"
import QuestionAndAnswerToggle from "../../../components/QuestionAndAnswerToggle"
import FlagVn from "../../../images/flag_vn.svg"
import FlagJp from "../../../images/flag_jp.svg"

import FaceImg01 from "../../../images/interview/02/face_01.jpg"
import FaceImg02 from "../../../images/interview/02/face_02.jpg"

import css from "../stylesheets/interview.module.scss"
import { graphql } from "gatsby"
import Img from "gatsby-image"

export default ({ data }) => {
  return (
    <div className={css.InterviewDetail}>
      <Seo
        title={`Working and Self-Caring for Mental and Physical Conditions | INTERVIEW`}
        lang={`en`}
      />
      <LayoutInterview lang={`en`}>
        <Info2column id={`interview-02`} noBorder={true}>
          <div>
            <Img fluid={data.image1.childImageSharp.fluid} />
          </div>
          <div>
            <p className={css.date}>{`2020.3.25`}</p>
            <h3 className={`mb20`}>
              Working and Self-Caring for Mental and Physical Conditions
            </h3>
            <p>
              Kenichi Saito
              <small>（Age: 39）</small>× Nguyen Ngoc Thuy
              <small>（Age: 26）</small>
            </p>
            <p>
              Thuy, from Vietnam, came to{" "}
              <ModalLink id={`facility`} className={`linkUnderline`}>
                <span style={{ color: "black" }}>
                  ‘Graceful SHIOJIRI,’
                  <img src={IconPhoto} alt="" />
                </span>
                <div>
                  <h3>Workplace</h3>
                  <Img fluid={data.facility1.childImageSharp.fluid} />
                  <Img fluid={data.facility2.childImageSharp.fluid} />
                  <Img fluid={data.facility3.childImageSharp.fluid} />
                  <Img fluid={data.facility4.childImageSharp.fluid} />
                </div>
              </ModalLink>{" "}
              an intensive care home for the elderly, in Shiojiri, Nagano as{" "}
              <AnchorLink
                className={`linkUnderline`}
                to="/en/basic-information/kaigo/#section-2"
              >
                an EPA Care Worker Candidate
              </AnchorLink>
              . She completed a ten-week training program and started working at
              the facility two years ago.
            </p>
            <p>
              Kenichi Saito, the head of care workers, has been working with
              Thuy and thinks that she has a good sense for nursing care. They
              told us about their opinions about their jobs in the environment
              with many other Vietnamese care workers.
            </p>
            <h3>PROFILE</h3>
            <table className={`profileTable`}>
              <tbody>
                <tr>
                  <th>Nane</th>
                  <td>Nguyen Ngoc Thuy</td>
                </tr>
                <tr>
                  <th>Workplace</th>
                  <td>
                    Care Worker at an intensive care home for the elderly run by
                    SUN-VISION Social Welfare Corporation ‘Graceful SHIOJIRI’
                  </td>
                </tr>
                <tr>
                  <th>Birthplace</th>
                  <td>
                    <img src={FlagVn} alt="Vietnam" className={`flag`} />
                    Hanoi, Vietnam
                  </td>
                </tr>
                <tr>
                  <th>Age</th>
                  <td>26 (Born in 1993) </td>
                </tr>
                <tr>
                  <th>Education</th>
                  <td>
                    Graduated from the College of Nursing at Hanoi Medical
                    College
                  </td>
                </tr>
                <tr>
                  <th>Work Experience</th>
                  <td>Working as a nurse at a general hospital for 1 year</td>
                </tr>
                <tr>
                  <th>Residence Status at Entry to Japan</th>
                  <td>EPA Care Worker Candidate</td>
                </tr>
                <tr>
                  <th>Arrival Date in Japan</th>
                  <td>May 2018</td>
                </tr>
              </tbody>
            </table>

            <table className={`profileTable`}>
              <tbody>
                <tr>
                  <th>Name</th>
                  <td>Kenichi Saito</td>
                </tr>
                <tr>
                  <th>Workplace</th>
                  <td>
                    The Head of Care Workers at an intensive care home for the
                    elderly run by SUN-VISION Social Welfare Corporation
                    ‘Graceful SHIOJIRI’
                  </td>
                </tr>
                <tr>
                  <th>Birthplace</th>
                  <td>
                    <img src={FlagJp} alt="Japan" className={`flag`} />
                    Suwa City, Nagano
                  </td>
                </tr>
                <tr>
                  <th>Age</th>
                  <td>39 (Born in 1981)</td>
                </tr>
                <tr>
                  <th>Education</th>
                  <td>
                    Bachelor of Sociology in Social Welfare at Momoyama Gakuin
                    University
                  </td>
                </tr>
                <tr>
                  <th>Job Starting Date</th>
                  <td>April 2003</td>
                </tr>
              </tbody>
            </table>
          </div>
        </Info2column>
        <QuestionAndAnswerToggle
          question={`How do you feel about nursing care?`}
        >
          <QuestionAndAnswer2colmun>
            <div>
              <Img fluid={data.image2.childImageSharp.fluid} />
            </div>
            <CommentText src={FaceImg01} name={`Thuy`}>
              <p>
                I often struggle because there are many things on the list. It
                is fun once you get used to it. For the first six months, I
                slowly worked with my seniors. I spoke to the residents everyday
                and got to know each resident’s feelings and personality. Since
                then, my skills related to nursing care have been improved.
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
          <QuestionAndAnswer2colmun>
            <div></div>
            <CommentText src={FaceImg02} direction={`right`} name={`Kenichi`}>
              <p>
                Thuy is a bright and caring person. Whether you are Japanese or
                not, everyone experiences the difficulties of obtaining the
                skills related to nursing care. Thuy treasures every single
                resident and deals with them accordingly. One afternoon, she was
                helping a resident to shave. What was happening, Thuy?
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
          <QuestionAndAnswer2colmun>
            <div></div>
            <CommentText src={FaceImg01} name={`Thuy`}>
              <p>
                He had a trouble with his skin so it was better for me to shave
                him before applying the medicine.
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
          <QuestionAndAnswer2colmun>
            <div></div>
            <CommentText src={FaceImg02} direction={`right`} name={`Kenichi`}>
              <p>
                I see. She perceives the best solution for each resident at the
                right timing. Her observation ability is quite good.
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
          <QuestionAndAnswer2colmun>
            <div>
              <Img fluid={data.image3.childImageSharp.fluid} />
            </div>
            <CommentText src={FaceImg01} name={`Thuy`}>
              <p>
                I'm not forcing myself to perceive, but I involuntarily notice.
                What’s harder for me is planning{" "}
                <ModalLink id={`modal-1`} style={{ display: `inline-block` }}>
                  <span>
                    ‘person-centered care.’
                    <img src={IconInfo} alt="" />
                  </span>
                  <div>
                    <h3>Person-Centered Care</h3>
                    <p>
                      Person-centered care refers to valuing each person’s ideas
                      and wishes while living in a group environment. The care
                      workers plan an ‘individual support scheme’ and carry it
                      out according to each condition, considering daily
                      observations of the residents.
                    </p>
                  </div>
                </ModalLink>{" "}
                Each resident has different physical conditions and
                personalities. At first, I was occasionally told not to help
                them too much.
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
          <QuestionAndAnswer2colmun>
            <div></div>
            <CommentText src={FaceImg02} direction={`right`} name={`Kenichi`}>
              <p>
                If we help them all the time, it would be much easier for us to
                work. In fact, nursing care refers to allowing those who need
                help to do things that they have abilities to achieve by
                themselves. Nursing care is interesting because you always need
                to think about each case.
              </p>
              <p>
                People with dementia suddenly lose the ability to do something
                they were able to do a few hours ago. On the other hand, they
                are abruptly able to do something they were unable to do. There
                are endless things that we can do for them utilizing our
                experience and abilities. We think on the spot and discuss with
                coworkers. This is also the difficult part of nursing care
                though.
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
          <QuestionAndAnswer2colmun>
            <div>
              <Img fluid={data.image4.childImageSharp.fluid} />
            </div>

            <div>
              <CommentText src={FaceImg01} name={`Thuy`}>
                <p>
                  That’s right. I think talking to each resident and getting to
                  know the person is very important.
                </p>
              </CommentText>
              <CommentText src={FaceImg02} direction={`right`} name={`Kenichi`}>
                <p>
                  To do so, having verbal communication will be important, but,
                  every international worker has a hard time understanding
                  dialects. Did you also have any dialects you couldn’t
                  understand?
                </p>
              </CommentText>
            </div>
          </QuestionAndAnswer2colmun>
          <QuestionAndAnswer2colmun>
            <div></div>
            <CommentText src={FaceImg01} name={`Thuy`}>
              <p>
                One time, I asked a resident if they wanted to change their
                pajamas and they answered “Kigaen.” I thought they said
                “Kigaeru,” so I continued helping them to change, however, they
                told me, “I said Kigaen.” I asked my senior what the problem
                was, then discovered, in Nagano dialect, ‘Kigaenai (not to
                change)’ is said as ‘Kigaen.’
              </p>
              <p>
                When I don’t understand, I keep asking them over and over again.
                I say things like, “Please say that again,” “Please write on a
                paper,” “How do you say in other words?” and “I come from
                overseas so my Japanese is still not good enough. Please teach
                me.” Then the residents will teach me slowly and politely.
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
        </QuestionAndAnswerToggle>

        <QuestionAndAnswerToggle
          question={`What are the distinctive features of your workplace? `}
        >
          <QuestionAndAnswer2colmun>
            <div>
              <Img fluid={data.image5.childImageSharp.fluid} />
            </div>

            <CommentText src={FaceImg01} name={`Thuy`}>
              <p>
                We use assistive devices to help transferring residents, such as
                ‘lifts’ and ‘slide boards.’ These assistive devices are not very
                common in Vietnam yet, so I would like to bring back such
                techniques.
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
          <QuestionAndAnswer2colmun>
            <div>
              <Img fluid={data.image6.childImageSharp.fluid} />
            </div>

            <CommentText src={FaceImg02} direction={`right`} name={`Kenichi`}>
              <p>
                Our company values a ‘No Lifting Policy.’ It refers to
                minimizing the action of ‘holding’ and ‘lifting up.’ It
                decreases both the residents and care workers’ burden. This is
                the reason why we apply such assistive devices.
              </p>
              <p>
                We also encourage our care workers to do{" "}
                <ModalLink id={`modal-2`} style={{ display: `inline-block` }}>
                  <span>
                    ‘self-care.’
                    <img src={IconInfo} alt="" />
                  </span>
                  <div>
                    <h3>Self-care</h3>
                    <p>
                      Managing your own health condition is called self-care. It
                      aims at learning methods to deal with fatigue and stress
                      from work. It also includes spending time for yourself
                      other than exercising, sleeping and working to control
                      both mental and physical conditions.
                    </p>
                  </div>
                </ModalLink>{" "}
                We participated in a self-care workshop organized by
                psychiatrists when we visited and had training at a nursing home
                in San Francisco.
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
        </QuestionAndAnswerToggle>

        <QuestionAndAnswerToggle
          question={`Is there anything you are making an effort to work on together? `}
        >
          <QuestionAndAnswer2colmun>
            <div>
              <Img fluid={data.image7.childImageSharp.fluid} />
            </div>

            <CommentText src={FaceImg02} direction={`right`} name={`Kenichi`}>
              <p>
                We are working with 14 EPA Care Worker Candidates from Vietnam.
                To support them, we provide Japanese lessons for about 20 hours
                every month and four of the lessons are taught by Japanese
                teachers. From the second year, lessons for the national
                examination for a Certified Care Worker qualification are also
                held once or twice in a month.
              </p>
              <p>
                When we accepted EPA Care Worker Candidates for the first time
                in 2016, we had no clue of what and how to support them. As we
                spent our time together, we gradually found that they often
                suppress their feelings, thinking that they would cause trouble
                by releasing them. So even if they say, “I’m alright,” we tried
                to keep asking them or changed the way we questioned.
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
          <QuestionAndAnswer2colmun>
            <div></div>
            <CommentText src={FaceImg01} name={`Thuy`}>
              <p>
                Mr. Saito not only teaches me things we don't know, but always
                greets and asks me how I feel. Even if I say, “I'm good,” he
                keeps asking me if I really am.
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
          <QuestionAndAnswer2colmun>
            <div></div>
            <CommentText src={FaceImg02} direction={`right`} name={`Kenichi`}>
              <p>
                Senior EPA Care Worker Candidates support the juniors, so my
                role has lessened these days. I want them to ask for help
                anytime when they need it, though.
              </p>
              <p>
                In 2019, we organized a study group session and a dining party
                for the other workers to get to know more about Vietnamese
                culture. I thought getting to know the difference between
                Vietnam and Japan would make it easier for everyone to work
                together.
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
          <QuestionAndAnswer2colmun>
            <div>
              <Img fluid={data.image8.childImageSharp.fluid} />
            </div>
            <CommentText src={FaceImg01} name={`Thuy`}>
              <p>
                In the session, I pointed out that Japanese people often say
                that it’s OK even if it’s not, and we repeat making the same
                mistakes without realizing it. So I asked them to correct us
                when we make mistakes.
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
          <QuestionAndAnswer2colmun>
            <div></div>
            <CommentText src={FaceImg02} direction={`right`} name={`Kenichi`}>
              <p>It was good to know our cultural differences. </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
          <QuestionAndAnswer2colmun>
            <div></div>

            <CommentText src={FaceImg01} name={`Thuy`}>
              <p>
                Since then, my Japanese coworkers correct my mistakes. I also
                asked them to talk slowly so it is more comfortable to
                communicate with other workers than before.
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
        </QuestionAndAnswerToggle>

        <QuestionAndAnswerToggle question={`How is your life in Japan?`}>
          <QuestionAndAnswer2colmun>
            <div>
              <Img
                fluid={data.image9.childImageSharp.fluid}
                className={`mb20`}
              />
              <Img
                fluid={data.image10.childImageSharp.fluid}
                className={`mb20`}
              />
              <Img fluid={data.image11.childImageSharp.fluid} />
            </div>

            <CommentText src={FaceImg01} name={`Thuy`}>
              <p>
                I live with two other Vietnamese coworkers in an apartment which
                my company prepared for us. To my work place, it takes about ten
                minutes by bicycle.
                <br />
                I prepare all my meals by myself. I can also order food at my
                workplace but I bring my own lunch everyday. My roommates also
                cook for themselves. But when someone is at work and the other
                two have days off, they cook Vietnamese food and we have it
                together when we are all at home. One of my roommates is good at
                cooking, so my job is doing the dishes (laughs).
                <br />
                You can buy almost anything you need at a supermarket. When I
                need Vietnamese condiments or duck meat, which you can rarely
                buy at a local supermarket in Japan, I order them online. I
                don’t feel inconvenienced.
                <br />
                On my day off, I go to Matsumoto, the biggest town in Nagano,
                for shopping and walking around Matsumoto Castle. Now I'm more
                used to riding the train, it only takes about 15 minutes to
                Matsumoto.
                <br />I have many Vietnamese coworkers so I don’t feel lonely
                all the time.
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
        </QuestionAndAnswerToggle>

        <QuestionAndAnswerToggle question={`What is your future goal? `}>
          <QuestionAndAnswer2colmun>
            <div></div>
            <CommentText src={FaceImg01} name={`Thuy`}>
              <p>
                The top thing on my list is to pass the JLPT (Japanese-Language
                Proficiency Test) N1. In two years time, I want to pass the
                national examination for Certified Care Worker. After obtaining
                the qualification, I'm hoping to teach nursing care in Vietnam.
                There are only few nursing homes for the elderly, even as the
                elderly population increases. So more nursing homes will be
                needed in the future in Vietnam.
                <br />
                For now, I feel that the residents are like my family. When I
                feel down, they sometimes cheer me up. Talking to them is so
                much fun that I sometimes forget my fatigue.
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
          <QuestionAndAnswer2colmun>
            <div>
              <Img fluid={data.image12.childImageSharp.fluid} />
            </div>
            <CommentText src={FaceImg02} direction={`right`} name={`Kenichi`}>
              <p>
                It has been three years since we started accepting international
                care workers. We are willing to support them to happily work in
                teams, as well as comfortably live in Japan.
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
        </QuestionAndAnswerToggle>

        <div className={`mt30`}>
          <Info2column noBorder={true}>
            <div></div>
            <div>
              <p style={{ margin: 0 }}>Workplace</p>
              <h3 className={`mb10`}>
                <ModalLink id={`facility`} className={`linkUnderline`}>
                  <span style={{ color: "black" }}>
                    Graceful SHIOJIRI
                    <img src={IconPhoto} alt="" />
                  </span>
                  <div>
                    <h3>Workplace</h3>
                    <Img fluid={data.facility1.childImageSharp.fluid} />
                    <Img fluid={data.facility2.childImageSharp.fluid} />
                    <Img fluid={data.facility3.childImageSharp.fluid} />
                    <Img fluid={data.facility4.childImageSharp.fluid} />
                  </div>
                </ModalLink>
              </h3>
              <p className={`mb40`}>
                A 13-story building located in front of Shiojiri station. In the
                building, there is a group home, adult daycare center, licensed
                child care center and residence for the general public, and the
                intensive care home for the elderly. In the intensive care home
                for the elderly (3-9th floor), there are about 20 residents and
                8 care workers on each floor. Branch facilities are also located
                in another part of Nagano, Aichi and Gifu. The total number of
                EPA Care Worker Candidates accepted has exceeded over 70 since
                2016.
              </p>
              <p>
                <a
                  href={`https://sun-vision.or.jp`}
                  rel={`noopener noreferrer`}
                  target={`_blank`}
                >
                  https://sun-vision.or.jp
                </a>
              </p>

              <div className="credit">
                <p>
                  Text by Tami Ono
                  <br />
                  Photo by Natsuki Kuroda
                  <br />
                  Translation by Mihoko Tsujita, Dean Humphrey
                </p>
              </div>

              <div className="mt40">
                <ColumnGrid>
                  <div className={css.commonButton}>
                    <CommonButton to={`/en/interview/1`} align={`center`}>
                      PREV
                    </CommonButton>
                  </div>
                  <div className={css.commonButton}>
                    <CommonButton to={`/en/interview/`} align={`center`}>
                      BACK
                    </CommonButton>
                  </div>
                  <div className={css.commonButton}>
                    <CommonButton to={`/en/interview/3`} align={`center`}>
                      NEXT
                    </CommonButton>
                  </div>
                </ColumnGrid>
              </div>
            </div>
          </Info2column>
        </div>
      </LayoutInterview>
    </div>
  )
}

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "interview/02/01.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    image2: file(relativePath: { eq: "interview/02/02.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    image3: file(relativePath: { eq: "interview/02/03.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    image4: file(relativePath: { eq: "interview/02/04.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    image5: file(relativePath: { eq: "interview/02/05.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    image6: file(relativePath: { eq: "interview/02/06.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    image7: file(relativePath: { eq: "interview/02/07.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    image8: file(relativePath: { eq: "interview/02/08.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    image9: file(relativePath: { eq: "interview/02/09.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    image10: file(relativePath: { eq: "interview/02/10.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    image11: file(relativePath: { eq: "interview/02/11.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    image12: file(relativePath: { eq: "interview/02/12.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    facility1: file(relativePath: { eq: "interview/02/facility_01.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    facility2: file(relativePath: { eq: "interview/02/facility_02.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    facility3: file(relativePath: { eq: "interview/02/facility_03.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    facility4: file(relativePath: { eq: "interview/02/facility_04.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
