import React from "react"
import css from "./stylesheets/CommentText.module.scss"

function CommentText(props) {
  let bodyDirection = ""
  let headDirection = ""
  if (props.direction && props.direction === "right") {
    bodyDirection = css.bodyIsRight
    headDirection = css.headIsRight
  }

  return (
    <div>
      <div className={css.head + ` ` +headDirection}>
        <figure>
          <img src={props.src} alt="" />
        </figure>
        <p dangerouslySetInnerHTML={{ __html: props.name }} />
      </div>
      <div className={css.body + ` ` + bodyDirection}>
        <div className={css.bodyInner}>{props.children}</div>
      </div>
    </div>
  )
}

export default CommentText
