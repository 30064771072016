import React from "react"
import { Link } from "gatsby"
import LayoutBasic from "./LayoutBasic"
import Arrow from "../images/icon_back_arw.svg"
import BasicText from "./BasicText"
import css from "./stylesheets/LayoutInterview.module.scss"
import CenterContentLayout from "./CenterContentLayout"

export default props => {
  let parentPath =
    props.lang === "easyja" ? `/interview` : `/` + props.lang + `/interview`

  return (
    <LayoutBasic lang={props.lang} style={{ marginBottom: `0` }}>
      <BasicText>
        <CenterContentLayout>
          <h1 className={`pageTitle`}>INTERVIEW</h1>
          <p>
            <Link to={parentPath} className={css.back}>
              <span>
                <img src={Arrow} alt="BACK" /> BACK
              </span>
            </Link>
          </p>
          <div className={css.content}>{props.children}</div>
        </CenterContentLayout>
      </BasicText>
    </LayoutBasic>
  )
}
